<template>
  <el-container class="app-container">
    <el-header style="margin:10px 0px;font-size:20px;">{{ $t('page.BasicInformation') }}</el-header>
    <!-- <el-main style=""> -->
    <el-row class="row-center">
      <el-col :span="10"><div class="grid-content" />
        <el-form ref="ruleForm" :disabled="fordisabled" :label-position="labelPosition" :model="ruleForm" :rules="rules" label-width="140px">

          <el-form-item label="STYLE" prop="style">
            <el-select v-model="ruleForm.style" clearable filterable :placeholder="$t('page.selectPlaceholder')" @change="styleSelectChange">
              <el-option
                v-for="item in styleOptions"
                :key="item.id"
                :label="item.styleName"
                :value="item.styleName"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="商品一级类别" prop="category1Code">
            <el-select v-model="ruleForm.category1Code" filterable :placeholder="$t('page.selectPlaceholder')" :disabled="disabled">
              <el-option
                v-for="item in CategoryOptions"
                :key="item.id"
                :label="item.categoryName"
                :value="item.categoryName"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="下单时间" prop="orderDay">
            <el-input v-model="ruleForm.orderDay" oninput="value=value.replace(/[^0-9.]/g,'')" :placeholder="$t('page.inputPlaceholder')" @input="getVolume" />
          </el-form-item>
          <el-form-item label="L/T时间" prop="ltDay">

            <el-input v-model="ruleForm.ltDay" oninput="value=value.replace(/[^0-9.]/g,'')" :placeholder="$t('page.inputPlaceholder')" @input="getVolume" />
          </el-form-item>
          <el-form-item label="国内仓库处理时间" prop="warehouseProcessDay">

            <el-input v-model="ruleForm.warehouseProcessDay" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入" @input="getVolume" />
          </el-form-item>

          <el-form-item label="运输时间" prop="transportDay">
            <el-input v-model="ruleForm.transportDay" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入" @input="getVolume" />
          </el-form-item>
          <el-form-item label="平台上架时间" prop="platformShelfDay">
            <el-input v-model="ruleForm.platformShelfDay" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入" @input="getVolume" />
          </el-form-item>
          <el-form-item label="安全库存" prop="safetyInventory">
            <el-input v-model="ruleForm.safetyInventory" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入" @input="getVolume" />
          </el-form-item>
          <el-form-item label="TURNOVER" prop="turnover">
            <el-input v-model="ruleForm.turnover" placeholder="请输入" disabled="" />
          </el-form-item>

          <el-row class="grid-content" type="flex" justify="center">
            <el-button type="primary" style="width:100px" :loading="Loadingcommit" @click="handlesubmitForm('ruleForm')">{{ $t('page.submit') }}</el-button>
          </el-row>
        </el-form>
      </el-col>
    </el-row>

    <!-- </el-main> -->
  </el-container>
</template>

<script>
import { category, queryStyleList, getturnoverid, addturnover, modifyturnover } from '@/api/scm-api'

export default {
  data() {
    return {
      disabled: true,
      Loadingcommit: false,
      styleOptions: [],
      CategoryOptions: [],
      fordisabled: false, // 禁用文本框
      data: [],
      labelPosition: 'center',
      categoryLevel: 1,
      ruleForm: {
        style: '',
        category1Code: '',
        orderDay: '',
        ltDay: '',
        warehouseProcessDay: '',
        transportDay: '',
        platformShelfDay: '',
        safetyInventory: '',
        turnover: ''
      },
      rules: {
        style: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        category1Code: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        orderDay: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        ltDay: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        warehouseProcessDay: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        transportDay: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        platformShelfDay: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        safetyInventory: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        turnover: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }]

      }
    }
  },
  computed: {
    // 获取输入框的值
    formParams() {
      const { style, category1Code, ltDay, warehouseProcessDay, transportDay, platformShelfDay, safetyInventory, turnover } = this.ruleForm
      return Object.assign({}, { style, category1Code, ltDay, warehouseProcessDay, transportDay, platformShelfDay, safetyInventory, turnover })
    },
    // 生成对应的请求参数
    Parameter() {
      return Object.assign({}, this.formParams)
    }
  },
  mounted() {
    // 查看时 禁用for
    if (this.$route.query && this.$route.query.disabled) {
      this.fordisabled = true
    }
    this._queryStyleList()
    // this._queryCategorylist()
    this._getturnoverid()
  },
  methods: {
    // 获取站点信息数据
    async _getturnoverid(query) {
      const { id } = this.$route.query
      if (id) {
        const { datas } = await getturnoverid(id)
        this.ruleForm = datas
      }
    },

    // 获取style数据
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    styleSelectChange(value) {
      const data = this.styleOptions.find(item => item.styleName === value)
      this._category(data.styleName)
    },
    // 商品类别
    async _category(id) {
      const { datas } = await category(id)
      this.ruleForm.category1Code = datas.category1Code
    },
    // 点击提交按钮将表单数据提交至后台
    handlesubmitForm(query) {
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) return false
        const param = this.Parameter
        const { id } = this.$route.query
        if (id) {
          param.id = id
          this._modifyturnover(param)
        } else {
          this._addturnover(param)
        }
      })
    },
    // 新增
    async _addturnover(query) {
      try {
        this.Loadingcommit = true
        const Parameter = Object.assign({}, this.ruleForm)
        const { code, msg } = await addturnover(Parameter)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })

          this.$router.push({
            path: '../',
            append: true
          })
          this.Loadingcommit = false
        }
      } finally {
        this.Loadingcommit = false
      }
    },
    // 修改
    async _modifyturnover(query) {
      this.Loadingcommit = true
      const Parameter = Object.assign({}, this.ruleForm)
      const { code, msg } = await modifyturnover(Parameter)
      if (code === 0) {
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this.Loadingcommit = false
        this.$router.push({
          path: '../',
          append: true
        })
      } else { // 其他状态
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this.Loadingcommit = false
      }
      if (code === 1) {
        this.Loadingcommit = false
      }
    },
    getVolume() {
      if (this.ruleForm.orderDay !== '' && this.ruleForm.ltDay !== '' && this.ruleForm.warehouseProcessDay !== '' && this.ruleForm.transportDay !== '' && this.ruleForm.platformShelfDay !== '' && this.ruleForm.safetyInventory !== '') {
        this.ruleForm.turnover = (Number(this.ruleForm.orderDay) + Number(this.ruleForm.ltDay) + Number(this.ruleForm.warehouseProcessDay) + Number(this.ruleForm.transportDay) + Number(this.ruleForm.platformShelfDay) + Number(this.ruleForm.safetyInventory))
        // this.ruleForm.cartonVolume = this.ruleForm.cartonVolume.toFixed(4)
      } else {
        this.ruleForm.turnover = ''
      }
    }

  }
}
</script>
